import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import React, { useState, useEffect } from 'react';


export default function Snack({ message, show, severity, onAutoClose}) {
    const [open, setOpen] = useState(false);

    const handleClose = () =>{
        setOpen(false);
        if(onAutoClose)
            onAutoClose();
    }

    useEffect(()=>{
        if(show){
            setOpen(true);
        }
    },[]);

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} >
            <MuiAlert elevation={6} variant="filled" onClose={handleClose}  severity={severity}>{message}</MuiAlert>
        </Snackbar>
    )
}
