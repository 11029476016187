import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper,
    Container,
    Typography,
    TextField,
    Alert,
    Box,
    LinearProgress
} from '@mui/material';
import axios from 'axios';
import config from '../auth/config';
import { useEffect, useState } from 'react';
import useAuth from '../auth/useAuth';
import semver from 'semver';

export default function UpdateDeviceSwDialog({ open, currentSw, updateCb, onClose, deviceId }) {

    const { iotToken } = useAuth();

    const [updateAvailable, setUpdateAvailable] = useState(false);
    const [latestVersion, setLatestVersion] = useState("");
    const [versionError, setVersionError] = useState(null);
    const [updateInProgress, setUpdateInProgress] = useState(false);

    useEffect(() => {
        async function checkForUpdate() {
            try {
                if (currentSw === "version not available") {
                    setVersionError("version not available");
                    return;
                }
                console.log("CurrentSw:",currentSw);
                let appName = currentSw.split(':')[0];
                let curVersion = currentSw.split(':')[1].trim();
                let url = config.iotUpdateUrl + '/checkupdate';
                let body = {
                    "id": deviceId,
                    "name": appName,
                };
                let resp = await axios.post(url,
                    body,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + iotToken
                        }
                    }
                );
                setLatestVersion(appName + ': ' + resp.data);
                setUpdateAvailable(semver.gt(resp.data, curVersion));
                setUpdateInProgress(false);
                setVersionError(null);
            } catch (err) {
                console.error("Error getting latest version:", err);
                setVersionError(err?.response?.data);
                setUpdateInProgress(false);
            }
        }
        checkForUpdate();
    }, [currentSw]);

    const updateDevice = () => {
        console.log("device update requested");
        setUpdateInProgress(true);
        updateCb();
    }

    return (
        <Dialog
            open={open}
            keepMounted
            fullWidth={true}
            maxWidth='md'
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>Device SW update</DialogTitle>

            <DialogContent>
                <Container>
                    <Typography sx={{ paddingBottom: 2 }}>
                        Latest version: <Typography fontWeight='bold' fontSize={18} display='inline'>{latestVersion}</Typography>
                    </Typography>
                    <Typography sx={{ paddingBottom: 2 }}>
                        Current version: <Typography fontWeight='bold' fontSize={18} display='inline'>{currentSw}</Typography>
                    </Typography>
                    {updateInProgress && <Box sx={{ paddingBottom: 2, width:'100%' }}>
                        <LinearProgress />
                        <Alert severity='info'>Update in progress...</Alert>
                    </Box>}
                </Container>
            </DialogContent>
            <DialogActions>
                {updateAvailable ? <Button onClick={updateDevice}>Update</Button> : versionError ? <Alert sx={{marginRight:2}} severity='warning'>{versionError}</Alert> :  <Alert sx={{marginRight:2}} severity='info'>Version is up to date</Alert>}
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}