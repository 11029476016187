{
  "name": "lifecycle-remastered",
  "version": "0.0.6",
  "description": "New version of lifecycle and iot-dashboard.",
  "repository": "https://jiiipeee@bitbucket.org/enveratech/lifecycle-remastered.git",
  "author": "JP Makela <juha-pekka.makela@envera.fi>",
  "license": "MIT",
  "private": true,
  "scripts": {
    "copyimages": "cp -vR ./src/img ./dist && cp ./src/img/favicon.ico ./dist",
    "prebuild": "rimraf dist && yarn copyimages",
    "start": "parcel src/index.html",
    "build": "parcel build src/index.html && yarn buildTerminal",
    "clean": "rimraf dist",
    "buildTerminal": "parcel build src/terminal/terminal.html",
    "postinstall": "cd ./src/modules/connect-communication-lib && yarn install && cd ../socketApi && yarn install && cd ../socketApiLegacy && yarn install",
    "sync:dev": "gsutil -m cp -r dist/* gs://lifecycle2.testflowone.io",
    "sync:prod": "*** NOT DONE YET! 'gsutil -m cp -r dist/* gs://lifecycle2.flowone.io' NOT DONE YET! ***"
  },
  "dependencies": {
    "@azure/msal-browser": "^2.24.0",
    "@azure/msal-react": "^1.4.0",
    "@date-io/luxon": "^2.14.0",
    "@emotion/react": "^11.9.0",
    "@emotion/styled": "^11.8.1",
    "@mui/icons-material": "^5.6.2",
    "@mui/material": "^5.7.0",
    "@mui/x-data-grid": "^5.11.0",
    "@mui/x-date-pickers": "^5.0.0-alpha.6",
    "axios": "^0.27.2",
    "luxon": "^2.4.0",
    "react": "^18.1.0",
    "react-dom": "^18.1.0",
    "react-router-dom": "^6.3.0",
    "semver": "^7.3.7"
  },
  "devDependencies": {
    "buffer": "^6.0.3",
    "events": "^3.3.0",
    "parcel": "^2.6.1",
    "process": "^0.11.10",
    "rimraf": "^3.0.2",
    "stream-browserify": "^3.0.0",
    "string_decoder": "^1.3.0",
    "util": "^0.12.4"
  }
}
