//localhost test environment
/*export default {
    loginUrl: "http://localhost:3007/auth/msauthlifecycle",
    flowoneApiUrl: "http://localhost:3007",
    flowoneIoUrl:"https://www.testflowone.io",
    iotDataUrl: 'https://iot-data-out.testflowone.io',
    iotApiUrl:'https://iot-api.testflowone.io',
    iotEventsUrl:'https://iot-events.testflowone.io',
    setupAssistantUrl:'https://config.testflowone.io',
    iotUpdateUrl:'https://iot-update.testflowone.io',
    defaultSocketServer:'https://iot-socket1.testflowone.io'
}*/

//testflowone environment

export default {
    loginUrl: "https://api.testflowone.io/auth/msauthlifecycle",
    flowoneApiUrl: "https://api.testflowone.io",
    flowoneIoUrl:"https://www.testflowone.io",
    iotDataUrl: 'https://iot-data-out.testflowone.io',
    iotApiUrl:'https://iot-api.testflowone.io',
    iotEventsUrl:'https://iot-events.testflowone.io',
    setupAssistantUrl:'https://config.testflowone.io',
    iotUpdateUrl:'https://iot-update.testflowone.io',
    defaultSocketServer:'https://iot-socket1.testflowone.io'
}
//flowone environment
/*export default {
    loginUrl: "https://api.flowone.io/auth/msauthlifecycle",
    flowoneApiUrl: "https://api.flowone.io",
    flowoneIoUrl:"https://www.flowone.io",
    iotDataUrl: 'https://iot-data-out.flowone.io',
    iotApiUrl:'https://iot-api.flowone.io',
    iotEventsUrl:'https://iot-events.flowone.io',
    setupAssistantUrl:'https://config.flowone.io',
    iotUpdateUrl:'https://iot-update.flowone.io',
    defaultSocketServer:'https://iot-socket1.flowone.io'
}*/