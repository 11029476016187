import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper,
    TableContainer,
    TextField
} from '@mui/material';
import { useState } from 'react';

export default function AddNoteDialog({ open, onClose, onSave }) {


    const [note, setNote] = useState("");

    return (
        <Dialog
            open={open}
            keepMounted
            fullWidth={true}
            maxWidth='md'
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>Add note to device</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <TableContainer component={Paper} sx={{ marginTop: 1 }}>
                        <TextField fullWidth multiline rows={4} value={note} onChange={(e) => { setNote(e.target.value) }} />
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={() => { onSave(note) }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}