import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper,
    TableContainer,
    Typography,
    TextField,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Alert,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';

export default function DeviceLifecycleDialog({ open, events, onClose, onStateDelete, onStateUpdate, deviceId }) {

    const [selectedState, setSelectedState] = useState('');
    const [currentState, setCurrentState] = useState('');
    const [nextStates, setNextStates] = useState([]);
    const [message, setMessage] = useState('');
    const [date, setDate] = useState(new Date());
    const [newStateValid, setNewStateValid] = useState(false);

    useEffect(() => {
        if (events.length) {
            setCurrentState(events[0].state);
            checkNewStateValid();
        }
    }, [events]);

    useEffect(() => {
        updateNextStates(currentState)
    }, [currentState]);

    const deleteLatestLifecycleState = () => {
        console.log("DeviceLifecycleDialog delete latest state");
        let resp=confirm("Do you really want to delete this lifecycle state?");
        if(resp){
             onStateDelete();
        }
    }

    const handleStateChange = (e) => {
        setSelectedState(e.target.value);
        checkNewStateValid();
    }

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        checkNewStateValid();
    }

    const checkNewStateValid = () => {
        if (selectedState, date) {
            setNewStateValid(true)
        }
    }

    const updateDeviceState = () => {
        console.log("Device state update requested.");
        if (newStateValid) {
            let event = {
                text:message,
                state:selectedState,
                timestamp:date
            }
            onStateUpdate(event);
        }
    }


    const updateNextStates = () => {
        const states = [];
        if (currentState === 'created') {
            states.push("assembled");
            states.push("destroyed");
        } else if (currentState === 'assembled') {
            states.push("assembled");
            states.push("accepted");
            states.push("destroyed");
        } else if (currentState === 'accepted') {
            states.push("stored_ext");
            states.push("destroyed");
        } else if (currentState === 'stored_ext') {
            states.push("delivered");
            states.push("stored_ext");
            states.push("returned");
        } else if (currentState === 'delivered') {
            states.push("active");
            states.push("returned");
        } else if (currentState === 'active') {
            states.push("returned");
            states.push("stored_ext");
            states.push("deactivated");
        } else if (currentState === 'deactivated') {
            states.push("returned");
            states.push("active");
        } else if (currentState === 'returned') {
            states.push("refurbished");
            states.push("destroyed");
            states.push("destroyed_broken");
        } else if (currentState === 'refurbished') {
            states.push("stored_ext");
            states.push("destroyed");
        }
        setNextStates(states);
        setSelectedState(states[0]);
    }


    return (
        <Dialog
            open={open}
            keepMounted
            fullWidth={true}
            maxWidth='lg'
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>Device Lifecycle State</DialogTitle>

            <DialogContent>
                <LocalizationProvider adapterLocale='en-AU' dateAdapter={AdapterLuxon}>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Divider textAlign='left' sx={{ paddingBottom: 3 }}>Change device {deviceId} state:{currentState} -&gt; {selectedState}</Divider>
                        <Box sx={{ paddingRight: 6, paddingLeft: 3 }}>
                            <FormControl fullWidth>
                                <InputLabel id="select-state-label">State</InputLabel>
                                <Select
                                    labelId="select-state-label"
                                    id="state-select"
                                    value={selectedState}
                                    label="State"
                                    onChange={handleStateChange}
                                >
                                    {nextStates.map((state) => (
                                        <MenuItem value={state}>{state}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ paddingTop: 2 }} fullWidth>
                                <TextField value={message} onChange={handleMessageChange} label="Message (optional)" ></TextField>
                            </FormControl>
                            <FormControl sx={{ paddingTop: 2 }}>
                                <DatePicker
                                    label="Date"
                                    value={date}
                                    mask="__/__/____"
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                        checkNewStateValid();
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                            <Grid container direction="row" justifyContent="right" alignItems="right">
                                <Button onClick={updateDeviceState} disabled={!newStateValid} >Change</Button>
                            </Grid>
                        </Box>
                        <Divider sx={{ paddingTop: 3, paddingBottom:2 }} textAlign='left'>History</Divider>
                        <TableContainer component={Paper} sx={{ marginTop: 1 }}>
                            <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell >State</TableCell>
                                        <TableCell >User</TableCell>
                                        <TableCell >Note</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {events.map((event, i) => (
                                        <TableRow key={event.updated} hover={true}>
                                            <TableCell >{new Date(event.timestamp).toLocaleDateString()}</TableCell>
                                            <TableCell >{event.state}</TableCell>
                                            <TableCell >{event.username.replace(/,/g, '.')}</TableCell>
                                            <TableCell >{event.text}</TableCell>
                                            <TableCell >{i == 0 ? <IconButton onClick={deleteLatestLifecycleState}><DeleteIcon color="error" /></IconButton> : ''}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                </LocalizationProvider >
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}