import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TextField
}
    from '@mui/material'

export default function DeviceDetailsVariables({ connection }) {



    const [variables, setVariables] = useState([
        { name: 'digitIntegerStats', value: {} },
        { name: 'digitDecimalStats', value: {} },
        { name: 'rawDigitsDecimalValue', value: -1 },
        { name: 'rawDigitsIntegerValue', value: -1 },
        { name: 'rawPointerValue', value: -1 },
        { name: 'digitsIntegerValue', value: -1 },
        { name: 'digitsDecimalValue', value: -1 },
        { name: 'pointerValue', value: -1 },
        { name: 'consumption', value: -1 },
        { name: 'lastSentTimeStamps', value: -1 },
        { name: 'digitsFail', value: -1 },
        { name: 'rssi', value: -1 }
    ]);


    useEffect(() => {
        async function subscibe() {
            if (connection) {
                connection.on('variable', deviceVariableChanged);
                console.log("subscribing to variables");
                let digitIntegerStats = await connection.getVariable('digitIntegerStats');
                deviceVariableChanged({ name: 'digitIntegerStats', value: digitIntegerStats });
                let digitDecimalStats = await connection.getVariable('digitDecimalStats');
                deviceVariableChanged({ name: 'digitDecimalStats', value: digitDecimalStats });
                let rawDigitsDecimalValue = await connection.getVariable('rawDigitsDecimalValue');
                deviceVariableChanged({ name: 'rawDigitsDecimalValue', value: rawDigitsDecimalValue });
                let rawDigitsIntegerValue = await connection.getVariable('rawDigitsIntegerValue');
                deviceVariableChanged({ name: 'rawDigitsIntegerValue', value: rawDigitsIntegerValue });
                let rawPointerValue = await connection.getVariable('rawPointerValue');
                deviceVariableChanged({ name: 'rawPointerValue', value: rawPointerValue });
                let digitsIntegerValue = await connection.getVariable('digitsIntegerValue');
                deviceVariableChanged({ name: 'digitsIntegerValue', value: digitsIntegerValue });
                let digitsDecimalValue = await connection.getVariable('digitsDecimalValue');
                deviceVariableChanged({ name: 'digitsDecimalValue', value: digitsDecimalValue });
                let pointerValue = await connection.getVariable('pointerValue');
                deviceVariableChanged({ name: 'pointerValue', value: pointerValue });
                let consumption = await connection.getVariable('consumption');
                deviceVariableChanged({ name: 'consumption', value: consumption });
                let lastSentTimeStamps = await connection.getVariable('lastSentTimeStamps');
                deviceVariableChanged({ name: 'lastSentTimeStamps', value: lastSentTimeStamps });
                let digitsFail = await connection.getVariable('digitsFail');
                deviceVariableChanged({ name: 'digitsFail', value: digitsFail });
                let rssi = await connection.getVariable('rssi');
                deviceVariableChanged({ name: 'rssi', value: rssi });
                console.log("variables subscribed.")


            }
        }
        subscibe();
        return function cleanUp() {
            connection.removeListener('variable', deviceVariableChanged)
        }
    }, [connection]);

    const deviceVariableChanged = (item) => {
        if (item.name === 'lastSentTimeStamps') {
            let val=JSON.parse(item.value);
            val.sendData = new Date(val.sendData).toLocaleString();
            val.sendPicture = new Date(val.sendPicture).toLocaleString();
            val.sendRssi = new Date(val.sendRssi).toLocaleString();
            item.value=val;
        }
        let hit = variables.findIndex(e => e.name == item.name);
        if (hit != -1) {
            variables.splice(hit, 1, item);
        } else {
            variables.push(item);
        }
        let arr = [];
        setVariables(arr.concat(variables));

    }

    return (
        <Grid container direction="row"
            sx={{
                gridAutoColumns: '1fr',
                gap: 1, display: 'grid',
            }}>
            <Grid item container direction="row" gridRow={1} justifyContent="center">
                <TableContainer component={Paper} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Variable</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {variables.map((item) => (
                                <TableRow>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{typeof (item.value) == "object" ? <TextField multiline fullWidth value={JSON.stringify(item.value, undefined,4)} /> : item.value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>
        </Grid>
    )

}