import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid,
    Box,
    LinearProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from '../auth/useAuth';
import config from '../auth/config';

//import { useTheme } from "@mui/material/styles";

export default function EventsDialog({ open, deviceId, onClose }) {

    //const theme = useTheme();
    const { iotToken } = useAuth();

    const [events, setEvents] = useState([]);
    const [filteredEvent, setFilteredEvents] = useState([]);
    const [filter, setFilter] = useState('all');
    const [loading, setLoading] = useState(false);

    let index = 0;

    useEffect(() => {
        async function getEvents() {
            try {
                setLoading(true);
                let url = config.iotEventsUrl + '/find';
                let eventFilter = undefined;
                if (filter != 'all') {
                    eventFilter = { action: filter }
                }
                let find = {
                    query: {
                        sourceId: deviceId,
                        ...eventFilter,
                    },
                    sort: { timestamp: -1 },
                    limit: 100
                }
                let response = await axios.post(url, find, {
                    headers: {
                        'Authorization': 'Bearer ' + iotToken
                    }
                });
                console.log("EventsDialog resp:", response);
                setLoading(false);
                setEvents(response?.data);
            } catch (err) {
                console.error("Error retreiving events:", err);
            }
        }
        getEvents();
    }, [filter]);

    const handleFilterChange = (e) => {
        console.log("handelFilterChange:", e);
        setFilter(e.target.value);
        setEvents([]);
    }

    return (
        <Dialog
            open={open}
            keepMounted
            fullWidth={true}
            maxWidth='lg'
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{ minHeight: '400px' }}
        >
            <DialogTitle>
                <Grid container direction="row">
                    <Grid item xs>
                        {deviceId} Events
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <InputLabel id="event-filter-label">Filter</InputLabel>
                            <Select
                                labelId="demo-filter-select-label"
                                id="event-select"
                                value={filter}
                                label="Filter"
                                onChange={handleFilterChange} >
                                <MenuItem value='all'>All</MenuItem>
                                <MenuItem value='data-history-changed'>Data History Changed</MenuItem>
                                <MenuItem value='device-reboot-manual'>Device Reboot Manual</MenuItem>
                                <MenuItem value='fix-movement'>Fix Movement</MenuItem>
                                <MenuItem value='highFlow'>High Flow</MenuItem>
                                <MenuItem value='highFlowEnd'>High Flow End</MenuItem>
                                <MenuItem value='image-ok'>Image ok</MenuItem>
                                <MenuItem value='Modem Restart'>Modem Restart</MenuItem>
                                <MenuItem value='rapidFlowEnd'>Rapid Flow End</MenuItem>
                                <MenuItem value='rapidFlowStart'>Rapid Flow Start</MenuItem>
                                <MenuItem value='reboot-black-images'>Reboot Black Images</MenuItem>
                                <MenuItem value='reboot-camera-failures'>Reboot Camera Failures</MenuItem>
                                <MenuItem value='rssi'>Rssi</MenuItem>
                                <MenuItem value='serviceNeeded'>Service Needed</MenuItem>
                                <MenuItem value='System Reboot'>System Reboot</MenuItem>
                                <MenuItem value='update'>Update</MenuItem>
                                <MenuItem value='updateMeta'>Update Meta</MenuItem>
                                <MenuItem value='updateSettings'>Update Settings</MenuItem>
                                <MenuItem value='valueAutoCorrection'>Value Auto Correction</MenuItem>
                                <MenuItem value='valueCorrection'>Value Correction</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {!loading ? <TableContainer component={Paper} sx={{ marginTop: 1, overflow: 'hidden' }}>
                        <Table stickyHeader sx={{ paddingTop: 5 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Action</TableCell>
                                    <TableCell align="center">Data</TableCell>
                                    <TableCell align="center">Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.map((event) => {
                                    let eventData = JSON.stringify(event.data, undefined, 4);
                                    let needAccordion = eventData.length > 100;
                                    return (
                                        <TableRow
                                            key={event.timestamp}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            hover={true}
                                        >
                                            <TableCell component="th" scope="row">
                                                {event.action}
                                            </TableCell>
                                            <TableCell align="center">
                                                {needAccordion ?
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={event.timestamp}
                                                            id={event.timestamp}
                                                        >
                                                            <Typography>{eventData.slice(0, 100) + '...'}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <TextField fullWidth multiline disabled value={eventData} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    : <Typography>
                                                        {eventData}
                                                    </Typography>
                                                }
                                            </TableCell>
                                            <TableCell align="center">{new Date(event.timestamp).toLocaleString()}</TableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer> : <Box sx={{ width: '100%' }}>
                        <LinearProgress sx={{ height: 10, borderRadius: 4 }} />
                    </Box>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}