import { useParams } from 'react-router-dom';

import DeviceDetails from '../components/DeviceDetails';

export default function DeviceView() {

    const { deviceId } = useParams();

    return (
        <DeviceDetails deviceId={deviceId} />
    )
}