import {
    Paper,
    Container,
    Typography,
    Box,
    Grid,
    Alert,
    Stack,
    Button,
    Tooltip,
    LinearProgress,
    Badge,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import {
    Phone,
    AppSettingsAlt,
    CloudOff,
    CloudDone,

} from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ContentPaste from '@mui/icons-material/ContentPaste';
import axios from 'axios';
import config from '../auth/config';

import React, { useState, useEffect, useRef } from 'react';
import useAuth from '../auth/useAuth';
import DeviceDetailsMenu from './DeviceDetailsMenu';
import Snack from './Snack';
import DeviceDetailsSkeleton from './DeviceDetailsSkeleton';
import AlarmsDialog from './AlarmsDialog';
import EventsDialog from './EventDialog';
import AddNoteDialog from './addNoteDialog';
import DetailsDialog from './DetailsDialog';
import UpdateDeviceSwDialog from './UpdateDeviceSwDialog';
import ConnectionLogDialog from './ConnectionLogDialog';
import DeviceLifecycleDialog from './DeviceLifecycleDialog';
import DeviceDetailsVariables from './DeviceDetailsVariables';
import DeviceClient from '../modules/connect-communication-lib';

export default function DeviceDetails(props) {

    const { deviceId } = props;
    const { token, iotToken, logout } = useAuth();
    const [deviceData, setDeviceData] = useState(null);
    const [latestImage, setLatestImage] = useState(null);
    const [alarms, setAlarms] = useState([]);
    const [activeAlarm, setActiveAlarm] = useState(null);
    const [snackData, setSnackData] = useState({ show: false, message: '', severity: 'info' });
    const [version, setVersion] = useState("version not available");
    const [connected, setConnected] = useState(false);
    const [connectionLog, setConnectionLog] = useState([]);
    const [alarmsDialogOpen, setAlarmsDialogOpen] = useState(false);
    const [eventDialogOpen, setEventsDialogOpen] = useState(false);
    const [noteDialogOpen, setNoteDialogOpen] = useState(false);
    const [serviceRequest, setServiceRequest] = useState({});
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [detailsMsg, setDetailsMsg] = useState("");
    const [detailsDialogTitle, setDetailsDialogTitle] = useState('Details');
    const [latestNote, setLatestNote] = useState(undefined);
    const [updateSwDialogOpen, setUpdateSwDialogOpen] = useState(false);
    const [connectionLogDialogOpen, setConnectionLogDialogOpen] = useState(false);
    const [lifecycleDialogOpen, setLifeCycleDialogOpen] = useState(false);
    const [showCopyHint, setShowCopyHint] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deviceNotFound, setDeviceNotFound] = useState(false);
    const [openAlarmsCount, setOpenAlarmsCount] = useState(0);
    const [lastConnected, setLastConnected] = useState(null);
    const [showVariables, setShowVariables] = useState(false);

    const deviceCon = useRef(null);
    const canvas = useRef(null);
    const canvasSettings = useRef(null);
    const latestValue = useRef(-1);

    useEffect(() => {
        if (deviceCon.current) {
            console.log("Cleaning device connection")
            deviceCon.current.removeListener('connected', deviceConnected);
            deviceCon.current.removeListener('disconnected', deviceDisconnected);
            deviceCon.current.removeListener('variable', deviceVariableChanged)
            deviceCon.current.disconnect()
            deviceCon.current.destroy();
        }
    }, [deviceId])

    useEffect(() => {
        async function getData() {
            console.log("Get data for device...", deviceId);
            setVersion("version not available");
            setDeviceData(null);
            setDeviceNotFound(false);
            //clearCanvases();
            setLatestImage(null);
            setServiceRequest({});
            setLatestNote(undefined);
            setShowVariables(false);
            //setLatestValue(-1);
            latestValue.current = -1;
            try {
                let headers = {
                    Authorization: 'Bearer ' + token
                }
                let url = config.flowoneApiUrl + '/lifecycle/devicedata/' + deviceId
                let deviceDataResp = await axios.get(url, {
                    headers
                });
                console.log("Response:", deviceDataResp);
                let deviceConn = undefined;
                if (deviceDataResp?.data) {
                    console.log('Tag:', deviceDataResp.data.tag);
                    if (deviceDataResp.data.tag.match(/smart-meters/)) {
                        console.log('smart meter detected!');
                        setSnackData({ show: true, message: 'SmartMeter detected', severity: 'warning' });
                        setDeviceData(deviceDataResp.data);
                        return;
                    }
                    console.log("Initializing lcp Client");
                    let socketServer = deviceDataResp.data.server;
                    if (!socketServer) socketServer = config.defaultSocketServer;
                    console.log("Connecting device at server:", socketServer);
                    deviceConn = new DeviceClient(iotToken, deviceId, socketServer);
                    deviceConn.on('connected', deviceConnected);
                    deviceConn.on('disconnected', deviceDisconnected);
                    deviceConn.on('variable', deviceVariableChanged);
                    try {
                        deviceCon.current = deviceConn;
                        await deviceConn.connect();
                    } catch (err) {
                        console.error("Device connection error");
                    }
                    setDeviceData(deviceDataResp.data);
                    updateHistory(deviceId, deviceDataResp.data.name);
                    //getLatestValue(deviceDataResp.data.settings.data.valueToUse);

                }
                if (deviceDataResp.data == "") {
                    console.log("device not found");
                    setSnackData({ show: true, message: 'Device not found', severity: 'error' });
                    setDeviceData({});
                    setDeviceNotFound(true);
                    clearCanvases();
                    return;
                }
                findLatestNote(deviceDataResp?.data?.notes);
                //get latest image
                let url2 = config.iotDataUrl + '/lcp-data/' + deviceId + '/latest/image';
                let image = await axios.get(url2, {
                    headers
                });
                setLatestImage(config.iotDataUrl + '/file/' + image?.data?.token);
                //get alarms
                let url3 = config.flowoneApiUrl + '/alarmsv2/get';

                headers['Content-Type'] = 'application/json';
                let query = {
                    sourceId: deviceId,
                    /*end: null*/
                };
                let respAlarms = await axios.post(url3, {
                    query,
                    sort: { start: -1 },
                    limit: 100,
                }, {
                    headers,

                });
                //console.log("Alarm:", respAlarms);
                //findActiveAlarm(respAlarms.data);
                setAlarms(respAlarms.data);
                url2 = config.flowoneApiUrl + '/servicerequests';
                //console.log("get serviceRequest status:",url2);

                let serviceRequests = await axios.get(url2,
                    {
                        params: {
                            query: {
                                queryFilter: {
                                    deviceId,
                                    fixed: null,
                                    closed: null,
                                }
                            },
                            limit: 1
                        },
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    });
                //console.log("serviceRequest:", serviceRequests);
                if (serviceRequests?.data) {
                    setServiceRequest(serviceRequests.data[0]);
                } else {
                    setServiceRequest({});
                }
                url = config.iotDataUrl + '/devices/' + deviceId + '/connectionlog';
                //console.log("Getting device connection log from:", url);
                let conlog = await axios.get(url, { headers: { 'Authorization': 'Bearer ' + iotToken } });
                //console.log("connection log:", conlog);
                getLastConnected(conlog?.data);
                setConnectionLog(conlog?.data);
                //console.log("Initalizing lcp-api...");

                let version = await deviceConn.version();
                console.log("version:", version);
                setVersion(version.appName + ': ' + version.appVersion);

                getLatestValue(deviceDataResp.data.settings.data.valueToUse);
            } catch (err) {
                console.error("Failed to get device details:", err);
                if (err?.response?.status === 401) {
                    console.log("Unauthorized!")
                    logout();
                }else{
                    setSnackData({ show: true, message: 'Failed to get device details', severity: 'error' });
                }
            } finally {
                setLoading(false);
            }
        };
        setLoading(true);
        getData();

    }, [deviceId, token, iotToken]);

    useEffect(() => {
        if (canvas.current) {
            let img = new Image();
            img.crossOrigin = true;
            img.onload = () => {
                let ctx = canvas.current.getContext("2d");
                ctx.clearRect(0, 0, canvas.current.width, canvas.current.height)
                ctx.drawImage(img, 0, 0);
            };
            img.src = latestImage;
            canvasSettings.current?.addEventListener("mouseenter", mouseEnter);
            canvasSettings.current?.addEventListener("mouseleave", mouseLeave);
            return () => {
                canvasSettings.current?.removeEventListener("mouseenter", mouseEnter);
                canvasSettings.current?.removeEventListener("mouseleave", mouseLeave);
            }
        }
    }, [latestImage]);

    useEffect(() => {
        findActiveAlarm(alarms);
    }, [alarms])

    const clearCanvases = () => {
        console.log("Clearing canvases...");
        if(canvas.current){
        let ctx = canvas.current.getContext("2d");
        ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
        let ctx2 = canvasSettings.current.getContext("2d");
        ctx2.clearRect(0, 0, canvasSettings.current.width, canvasSettings.current.height);
        }
    }

    const getLastConnected = (data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].status === "disconnected") {
                setLastConnected(data[i].timestamp);
                break;
            }
        }
    }
    const reloadDeviceData = async () => {
        try {
            let headers = {
                Authorization: 'Bearer ' + token
            }
            let url = config.flowoneApiUrl + '/lifecycle/devicedata/' + deviceId
            let deviceDataResp = await axios.get(url, {
                headers
            });
            console.log("Response:", deviceDataResp);
            if (deviceDataResp?.data) {
                setDeviceData(deviceDataResp.data);
            }
        } catch (err) {
            console.error("Failed to reload device data:", err);
        }
    }

    const updateHistory = (id, name) => {
        if (!id || !name) {
            console.log("updateHistory exit", id, name)
            return;
        }
        let history = localStorage.getItem("history");

        if (history) {
            history = JSON.parse(history);
        }
        else {
            history = [];
        }
        if (!history.find((device) => device.id === id)) {
            history.unshift({ id, name });
        } else {
            if (history.findIndex((device) => device.id == id) > 0) {
                history.splice(history.findIndex((device) => device.id == id), 1);
                history.unshift({ id, name });
            }
        }
        history = history.slice(0, 10);
        localStorage.setItem("history", JSON.stringify(history));
    }

    const findLatestNote = (notes) => {
        if (notes) {
            notes.sort((a, b) => a.timestamp < b.timestamp);
            setLatestNote(notes[0]);
        } else {
            setLatestNote(undefined);
        }
    }

    const findActiveAlarm = (alarms) => {
        let activeAlarmFound = false;
        let activeAlarmsCount = 0;
        for (const alarm of alarms) {
            if (alarm.end == null) {
                if (!activeAlarm) {
                    setActiveAlarm(alarm);
                    activeAlarmFound = true;
                }
                activeAlarmsCount++;
                //break;
            }
        }
        if (!activeAlarmFound) {
            setActiveAlarm(null);
        }
        setOpenAlarmsCount(activeAlarmsCount);
    }

    const addDeviceToOwnList = () => {
        console.log("adding device to own list:", deviceId);
        let ownDevices = localStorage.getItem('ownDevices');
        if (ownDevices) {
            console.log("own devices found:", ownDevices);
            ownDevices = JSON.parse(ownDevices);
        } else {
            console.log("own device not found");
            ownDevices = [];
        }
        if (!ownDevices.find((e) => e.id === deviceId)) {
            let newEntry = { id: deviceId, name: deviceData.name }
            ownDevices.push(newEntry);
            console.log("device added");
            localStorage.setItem('ownDevices', JSON.stringify(ownDevices));
            setSnackData({ show: true, message: 'Device added to own list', severity: 'success' });
        } else {
            console.log("devide already on list");
            setSnackData({ show: true, message: 'Device already on own list', severity: 'info' });
        }
    }

    const handleMetaDataSave = async (json) => {
        try {
            console.log("handleMetaDataSave:");
            let headers = {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
            let url = config.flowoneApiUrl + '/lifecycle/deviceMeta/' + deviceId;
            let data = JSON.parse(json);;
            //data.meta = JSON.parse(json);
            let resp = await axios.post(url, data, {
                headers
            });
            if (resp.status === 200) {
            }
            console.log("resp:", resp);
            setSnackData({ show: true, message: 'Data saved.', severity: 'success' });
        } catch (err) {
            console.log("Error saving data:", err);
            setSnackData({ show: true, message: 'Error saving data.', severity: 'error' });
        }
    }

    const resetSnackData = () => {
        setSnackData({ show: false, message: '', severity: 'info' });
    }
    const deviceConnected = async () => {
        console.log("Device connected.");

        setConnected(true);
        if (deviceCon.current) {
            let version = await deviceCon.current.version();
            console.log("version:", version);
            setVersion(version.appName + ': ' + version.appVersion);
            getLatestValue(deviceData?.settings?.data?.valueToUse);

        } else {
            console.warn("Device connected no connection object");
        }
    }

    const getLatestValue = async (valueToUse) => {
        let valueStr = 'pointerValue';
        console.log("getLatestValue")
        //console.log("deviceCon:",deviceCon)
        switch (valueToUse) {
            case 0:
                valueStr = 'pointerValue';
                console.log("pointerValue");
                break;
            case 2:
                valueStr = 'digitsIntegerValue';
                console.log("digitsIntegerValue");
                break;
            case 3:
                valueStr = "digitsDecimalValue";
                console.log('digitsDecimalValue');
                break;
            default:
                console.warn("Invalid valueToUse:", valueToUse);
        }
        console.log("Value source:", valueStr);
        let value = await deviceCon.current.getVariable(valueStr);
        console.log("settings latestValue:", value);
        latestValue.current = value;
    }

    const deviceVariableChanged = (val) => {
        //console.log("deviceVariableChanged:", val);
        if(val.name=="pointerValue"||val.name=="digitsIntegerValue"||val.name=="digitsDecimalValue"){
            latestValue.current = val.value;
        }

    }

    const deviceDisconnected = () => {
        console.log("Device disconnected.");
        setConnected(false);
    }

    const handleAlarmsDialogClose = () => {
        setAlarmsDialogOpen(false);
    }

    const handleMenuSelection = (value) => {
        switch (value) {
            case 'openAlarmsDialog':
                setAlarmsDialogOpen(true);
                break;
            case 'openEventsDialog':
                setEventsDialogOpen(true);
                break;
            case 'openUpdateDialog':
                setUpdateSwDialogOpen(true);
                break;
            case 'openConnectionLogDialog':
                setConnectionLogDialogOpen(true);
                break;
            case 'openLifecycleDialog':
                setLifeCycleDialogOpen(true);
                break;
            case 'openDeviceConfigDetails':
                setDetailsDialogTitle('Device Settings');
                setDetailsMsg(deviceData.settings);
                setDetailsDialogOpen(true);
                break;
            default:
                console.warn("DeviceDetails got non matching menuSelection:", value);
        }
    }

    const handleEventsDialogClose = () => {
        setEventsDialogOpen(false);
    }

    const handleConnectionLogDialogClose = () => {
        setConnectionLogDialogOpen(false);
    }

    const handleLifecycleDialogClose = () => {
        setLifeCycleDialogOpen(false);
    }

    const showServiceRequestDetails = () => {
        //console.log("showServiceRequestDetails");
        setDetailsDialogTitle('Device Service Requests');
        setDetailsMsg(serviceRequest);
        setDetailsDialogOpen(true);
    }

    const showNotes = () => {
        //console.log("showNotes");
        setDetailsMsg(deviceData.notes);
        setDetailsDialogTitle('Device Notes');
        setDetailsDialogOpen(true);
    }

    const showAlarm = () => {
        //console.log("Show alarm details");
        /*setDetailsMsg(activeAlarm);
        setDetailsDialogTitle('Device alarm');
        setDetailsDialogOpen(true);*/
        //changed to open alarms dialog
        setAlarmsDialogOpen(true);
    }

    const handleDetailsDialogClose = () => {
        setDetailsDialogOpen(false);
        setDetailsDialogTitle('Details')
        setDetailsMsg("");
    }

    const drawSettings = () => {
        //console.log("Draw settings:", latestValue.current);
        if (canvasSettings.current) {
            let ctx = canvasSettings.current.getContext('2d');
            ctx.clearRect(0, 0, canvasSettings.current.width, canvasSettings.current.height);
            ctx.strokeStyle = "#98DEAE";
            ctx.lineWidth = 3;
            if (deviceData && deviceData.settings && deviceData.settings.digits) {
                for (const p of deviceData?.settings?.digits?.positions) {
                    ctx.strokeRect(p.coords[0], p.coords[3], p.coords[2] - p.coords[0], p.coords[1] - p.coords[3]);
                }
            }
            if (deviceData && deviceData.settings && deviceData.settings.decimals)
                for (const p of deviceData?.settings?.decimals?.positions) {
                    if (p.coords) {
                        ctx.lineWidth = 2;
                        ctx.beginPath();
                        ctx.arc(p.coords.x, p.coords.y, 8, 0, 2 * Math.PI);
                        ctx.stroke();
                        ctx.lineWidth = 1;
                        ctx.beginPath();
                        ctx.rect(p.coords.x - 0.5, p.coords.y - 0.5, 1, 1);
                        ctx.stroke();
                    }
                }
            if (latestValue.current) {
                ctx.font = "48px Roboto";
                ctx.fillStyle = "#98DEAE";
                ctx.fillText(latestValue.current, 385, 50);
            }
        }
    }

    const mouseEnter = (e) => {
        drawSettings();
    }
    const mouseLeave = (e) => {
        let ctx = canvasSettings.current.getContext('2d');
        ctx.clearRect(0, 0, canvasSettings.current.width, canvasSettings.current.height);
    }

    const handleAddNoteDialogClose = () => {
        setNoteDialogOpen(false);
    }

    const handleUpdateDeviceSwDialogClose = () => {
        setUpdateSwDialogOpen(false);
    }

    const handleDeviceUpdate = () => {
        //console.log("Device SW update requested for device:", deviceId);
        if (deviceCon.current) {
            deviceCon.current.update();
        }
    }

    const handleNoteSave = async (note) => {
        //console.log("handleNoteSave:", note);
        setNoteDialogOpen(false);
        try {
            let resp = await axios.post(config.flowoneApiUrl + '/lifecycle/addnote', {
                deviceId,
                note,
                timestamp: new Date()
            }, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            setSnackData({ show: true, message: 'Note saved.', severity: 'success' });
            setLatestNote({
                note,
                timestamp: new Date()
            });
        } catch (err) {
            console.error("Failed to save note:", err);
        }
    }

    const phoneNumberMouseEnter = () => {
        setShowCopyHint(true);
    }
    const phoneNumberMouseLeave = () => {
        setShowCopyHint(false);
    }

    const copyPhoneNumberToClipboard = () => {
        navigator.clipboard.writeText(deviceData?.meta?.simnumber);
        setSnackData({ show: true, message: 'Copied to clipboard', severity: 'info' });
    }

    const openConfig = () => {
        window.open(config.setupAssistantUrl + '/#/' + deviceId, '_blank', 'noreferrer');
    }

    const updateDeviceState = async (event) => {
        console.log("device state update requeted for device:", deviceId);
        try {
            let url = config.iotDataUrl + '/devices/' + deviceId + '/lifecycle';
            let stateChange = {
                state: event.state,
                timestamp: event.timestamp,
                text: event.text
            };
            console.log("New state:", stateChange);
            let resp = await axios.post(url, stateChange, {
                headers: {
                    'Authorization': 'Bearer ' + iotToken
                }
            });
            console.log("response", resp);
            if (resp.status === 200) {
                await reloadDeviceData();
                setSnackData({ show: true, message: 'State changed', severity: 'success' });
            }
        } catch (err) {
            setSnackData({ show: true, message: 'State change failed!', severity: 'error' });
            console.error("Failed to update device state:", err);
        }
    }

    const deleteLatestDeviceState = async () => {
        console.log("device latest state delete requested for device", deviceId);
        try {
            let url = `${config.iotDataUrl}/devices/${deviceId}/lifecycle/latest`;
            let resp = await axios.delete(url, {
                headers: {
                    'Authorization': 'Bearer ' + iotToken
                }
            });
            if (resp.status === 200) {
                await reloadDeviceData();
                setSnackData({ show: true, message: 'State deleted', severity: 'success' });
            }
        } catch (err) {
            setSnackData({ show: true, message: 'Failed to delete latest device state', severity: 'error' });
            console.error("Failed to delete latest device state:", err);
        }
    }

    const handleShowVariablesChange = (e) => {
        setShowVariables(!showVariables);
    }
    //console.log("deviceDadaa", deviceData, latestValue, deviceNotFound);

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ textAlign: 'center',marginBottom:5 }}>
                {deviceData ?
                    <Box sx={{}}>
                        <Typography variant='h5'>{deviceData.name}
                            {deviceNotFound && <Alert severity="error">Device not found!</Alert>}
                        </Typography>

                        <Grid container direction="row"
                            sx={{
                                gridAutoColumns: '1fr',
                                gap: 1, display: 'grid',
                                
                            }}>
                            <Grid item container direction="row" gridRow={1} justifyContent="center">
                                <Typography>
                                    {deviceId}
                                    {loading && <LinearProgress />}
                                </Typography>

                            </Grid>
                            <Grid item container direction="row" gridRow={2} sx={{ marginTop: 5, marginBottom: 5 }} >
                                <Grid container item xs alignItems="flex-start" sx={{ marginLeft: 2, marginRight: 2, marginTop: 5 }}>
                                    <Stack sx={{ width: '100%' }} spacing={1} >
                                        {connected ? <Alert severity='success'>Connected</Alert> : <Alert severity='error'>Disconnected <Typography sx={{ fontSize: 10 }}>{new Date(lastConnected).toLocaleString()}</Typography></Alert>}
                                        {serviceRequest?.deviceId && <Alert sx={{ cursor: 'pointer' }} onClick={showServiceRequestDetails} severity='warning'>{serviceRequest.created.serviceRequestClass + ' - ' + serviceRequest.created.comment}</Alert>}
                                        {latestNote && <Alert sx={{ cursor: 'pointer' }} severity='info' action={deviceData.notes.length > 0 ? <Badge badgeContent={deviceData.notes.length}><StickyNote2Icon color="info" /></Badge> : ''} onClick={showNotes}>{latestNote.note}</Alert>}
                                    </Stack>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Paper maxWidth="sm" style={{ width: '100%', minHeight: 403, position: 'relative' }}>
                                        <canvas ref={canvas} className="canvasContainer" id="canv" style={{ width: "100%", position: 'absolute', top: 0, left: 0 }} width={640} height={480} />
                                        <canvas ref={canvasSettings} className="canvasContainer" onClick={openConfig} id="canvSettings" style={{ width: "100%", position: 'absolute', top: 0, left: 0 }} width={640} height={480} />
                                    </Paper>
                                </Grid>
                                <Grid container item xs sx={{ marginTop: 5, marginLeft: 2, marginRight: 2 }}>
                                    <DeviceDetailsMenu selectionChanged={handleMenuSelection} meta={deviceData?.meta ? deviceData.meta : {}} deviceId={deviceId} saveMetaData={handleMetaDataSave} />
                                </Grid>
                            </Grid>

                            <Grid item container direction="row" gridRow={3} sx={{ marginTop: 0, marginBottom: 5 }} >
                                <Grid xs container item sx={{ marginLeft: 2 }}>
                                    <Stack sx={{ width: '100%' }} spacing={1} >
                                        {deviceData?.tag?.match(/weaknetwork/) && <Alert severity="warning">Weak network device</Alert>}
                                        {deviceData?.tag?.match(/smart-meters/) && <Alert severity="warning">Smart device</Alert>}
                                        <Alert sx={{ cursor: 'pointer' }} onClick={copyPhoneNumberToClipboard} action={showCopyHint ? <ContentPaste color='primary' /> : ''} onMouseEnter={phoneNumberMouseEnter} onMouseLeave={phoneNumberMouseLeave} icon={<Phone fontSize='small' />} severity='info'>{deviceData?.meta?.simnumber ? deviceData?.meta?.simnumber : 'Not available'} </Alert>
                                        <Alert sx={{ cursor: 'pointer' }} icon={<AppSettingsAlt fontSize='small' />} severity='info' onClick={() => { setUpdateSwDialogOpen(true) }}>{version}</Alert>
                                    </Stack>
                                </Grid>
                                <Grid item container xs={6}>
                                    <Stack sx={{ width: '100%', marginLeft: 1, marginRight: 1 }} spacing={1} >
                                        {activeAlarm && <Alert sx={{ cursor: 'pointer' }} action={openAlarmsCount > 0 ? <Badge badgeContent={openAlarmsCount}><StickyNote2Icon color='error' /></Badge> : ''} onClick={showAlarm} severity='error'>{activeAlarm.name}</Alert>}
                                        {deviceData?.installed ? <Alert severity='success'>Installed</Alert> : <Alert severity='warning'>Not installed</Alert>}
                                    </Stack>
                                </Grid>
                                <Grid item container xs sx={{ marginRight: 2, marginLeft: 2 }}>
                                    <Stack sx={{ width: '100%', marginLeft: 2 }} spacing={1}>
                                        <Tooltip title="Add to own devices">
                                            <Button fullWidth variant='outlined' color='primary' size='large' startIcon={<AddCircleIcon fontSize="inherit" />} onClick={addDeviceToOwnList} ></Button>
                                        </Tooltip>

                                        <Button fullWidth variant='outlined' color='primary' size='large' onClick={() => { setNoteDialogOpen(true) }}>Add note</Button>
                                        {connected && <FormControlLabel control={<Checkbox checked={showVariables} onChange={handleShowVariablesChange} />} label="List variables" />}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        {showVariables && <DeviceDetailsVariables connection={deviceCon?.current} />}
                    </Box> :
                    <DeviceDetailsSkeleton deviceId={deviceId} />}
            </Paper>
            <AlarmsDialog alarms={alarms} deviceId={deviceId} open={alarmsDialogOpen} onClose={handleAlarmsDialogClose} />
            <EventsDialog deviceId={deviceId} open={eventDialogOpen} onClose={handleEventsDialogClose} />
            <DetailsDialog infoMsg={detailsMsg} title={detailsDialogTitle} onClose={handleDetailsDialogClose} open={detailsDialogOpen} />
            <AddNoteDialog open={noteDialogOpen} onClose={handleAddNoteDialogClose} onSave={handleNoteSave} />
            <DeviceLifecycleDialog onStateUpdate={updateDeviceState} onStateDelete={deleteLatestDeviceState} open={lifecycleDialogOpen} onClose={handleLifecycleDialogClose} deviceId={deviceId} events={deviceData?.events ? deviceData.events : []} />
            <ConnectionLogDialog open={connectionLogDialogOpen} onClose={handleConnectionLogDialogClose} connectionLog={connectionLog} />
            <UpdateDeviceSwDialog open={updateSwDialogOpen} currentSw={version} updateCb={handleDeviceUpdate} onClose={handleUpdateDeviceSwDialogClose} />
            {snackData.show && <Snack message={snackData.message} show={snackData.show} onAutoClose={resetSnackData} severity={snackData.severity} />}
        </Container >
    )
}

