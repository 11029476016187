'use strict';

const ioClient = require('socket.io-client');
const ss = require('socket.io-stream');

var Api = {}

Api.getManager = function (server, opts) {
    //console.log("getManager:",server,opts);
    return new ioClient.Manager(server,opts);
}
Api.getSS = function(){
    return ss;
}
Api.getSocketStream = function(socket){
    return ss(socket);
}

module.exports=Api;