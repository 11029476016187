const Q = require('q');
const EventEmitter = require('events').EventEmitter;
const api = require('./lib/api2');
const promisifyEvent = require('./lib/promisefy-event');

class Client extends EventEmitter {
  constructor(token, deviceId, server, oauth) {
    if (!deviceId) throw new Error('id is required');
    if (!token) throw new Error('token is required');

    super();

    this.token = token;
    this.id = deviceId;
    this.api = api;
    this.server = server;
    this.oauth = oauth;
    this.defaultTimeout = 5000;

    this.connected = false;

    this._onDeviceConnected = id => {
      if (id === this.id) {
        this.emit('connected');
        this.connected = true;
      }
    };

    api.on('device-connected', this._onDeviceConnected);

    this._onError = (id, err) => {
      if (id === this.id) {
        this.emit('error', err);
      }
    };

    api.on('error', this._onError);

    this._onDeviceDisconnected = id => {
      if (id === this.id) {
        this.emit('disconnected');
        this.connected = false;
      }
    };

    api.on('device-disconnected', this._onDeviceDisconnected);

    this._onVariable = (id, variable) => {
      if (id === this.id) {
        this.emit('variable', variable);
      }
    };

    api.on('variable', this._onVariable);

    this._onTrace = (id, params) => {
      if (id === this.id) {
        this.emit('trace', params);
      }
    };

    api.on('trace', this._onTrace);
  }

  destroy() {
    this.disconnect();
    api.removeListener('variable', this._onVariable);
    api.removeListener('error', this._onError);
    api.removeListener('device-connected', this._onDeviceConnected);
    api.removeListener('device-disconnected', this._onDeviceDisconnected);
    api.removeListener('trace', this._onTrace);
  }

  _connect(opts) {
    return new Promise((resolve, reject) => {
      let resolved = false;
      const connectedEvent = promisifyEvent(this, "connected").then(d => {
        resolved = true;
        resolve(d);
      });

      api.connect({
        token: this.token,
        id: this.id,
        server: this.server,
        oauth: this.oauth,
        serviceLogin: opts.serviceLogin
      });

      promisifyEvent(this, 'error')
        .then((e) => reject(e));

      Q.delay(opts.timeout|| this.defaultTimeout)
        .then(() => {
          if (!resolved) {
            reject(new Error('Connection timeout'));
            connectedEvent.cancel();
          }
        });
    });
  }

  connect(timeout) {
    return this._connect({ timeout: timeout })
  }

  serviceConnect(timeout) {
    return this._connect({ timeout: timeout, serviceLogin: true})
  }

  disconnect() {
    return new Promise((resolve) => {
      promisifyEvent(this, 'disconnected')
        .then(() => {
          resolve();
        });
      api.disconnect(this.id);
    });
  }

  listVariables() {
    return api.send(this.id, 'list-variables', null);
  }

  getVariable(variable) {
    return api.send(this.id, 'subscribe', [variable])
      .then((resp) => resp[0].value);
  }

  setVariable(variable, value) {
    return api.send(this.id, 'write', { variable, value });
  }

  requestStream(method, params, timeout) {
    return api.send(this.id, method, params||{}, timeout)
      .then((obj) => {
        if(obj.error) throw new Error(obj.error);
        if(!obj.remoteStream) throw new Error('no remoteStream');
        return obj.remoteStream;
      });
  }

  openWriteStream(method, params) {
    return api.createStreamTo(this.id, method, params||{});
  }

  sendCustom(method, msg, timeout) {
    return api.send(this.id, method, msg, timeout);
  }

  version() {
    return api.send(this.id, 'version-info');
  }

  update(version) {
    return api.sendCustom(this.id, 'update', {
      deviceId: this.id,
      updateTarget: "device",
      version: version,
    });
  }
}

module.exports = Client;

