import useAuth from "../auth/useAuth"
import Snack from "../components/Snack";
import {
    Container,
    Paper,
    Box,
    Typography,
    Grid,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Button
} from '@mui/material'

import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";


export default function ProfileView() {

    const [settings, setSettings] = useState({ autoopensearchresults: false, autoopenowndevices: false, autoopenhistory: false });
    const [snackShow, setSnackShow] = useState(false);

    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        let usettings = localStorage.getItem('usersettings');
        if (usettings) {
            usettings = JSON.parse(usettings);
            setSettings({
                ...settings,
                ...usettings
            });
        }

    }, []);

    const saveSettings = () => {
        localStorage.setItem('usersettings', JSON.stringify(settings));
        setSnackShow(true);
    }

    const handleAutopenChange = (e) => {
        console.log("handleAutoopenChange:", e.target.value);
        setSettings({
            ...settings,
            autoopensearchresults: e.target.value
        });
    }

    const handleAutopenOwnDevicesChange = (e) => {
        setSettings({
            ...settings,
            autoopenowndevices: e.target.value
        });
    }

    const handleAutopenHistoryChange = (e) => {
        setSettings({
            ...settings,
            autoopenhistory: e.target.value
        });
    }

    const resetSnackData = () => {
        setSnackShow(false);
    }

    const close = () => {
        navigate('/home');
    }

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ textAlign: 'center' }}>
                <Box sx={{}}>
                    <Typography variant='h5' sx={{ paddingBottom: 5 }}>User settings</Typography>
                    <Grid container direction="row" >
                        <Grid item container direction="row" gridRow={1} sx={{ marginLeft: 10, marginRight: 10, marginBottom: 5 }} justifyContent="center">
                            <Grid item container>
                                <FormControl fullWidth>
                                    <InputLabel id="autoopen-select-label">Auto open search results</InputLabel>
                                    <Select
                                        labelId="autopen-select-label"
                                        id="autopen-select"
                                        value={settings.autoopensearchresults}
                                        label="Auto open search results"
                                        onChange={handleAutopenChange}
                                    >
                                        <MenuItem value={false}>No</MenuItem>
                                        <MenuItem value={true}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item container sx={{ marginTop: 5 }} >
                                <FormControl fullWidth >
                                    <InputLabel id="autoopenown-select-label">Auto open own devices</InputLabel>
                                    <Select
                                        labelId="autopenown-select-label"
                                        id="autopenown-select"
                                        value={settings.autoopenowndevices}
                                        label="Auto open search results"
                                        onChange={handleAutopenOwnDevicesChange}
                                    >
                                        <MenuItem value={false}>No</MenuItem>
                                        <MenuItem value={true}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item container sx={{ marginTop: 5 }} >
                                <FormControl fullWidth >
                                    <InputLabel id="autoopenhistory-select-label">Auto open history</InputLabel>
                                    <Select
                                        labelId="autopehistory-select-label"
                                        id="autopenown-select"
                                        value={settings.autoopenhistory}
                                        label="Auto open search results"
                                        onChange={handleAutopenHistoryChange}
                                    >
                                        <MenuItem value={false}>No</MenuItem>
                                        <MenuItem value={true}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" gridRow={2} sx={{ marginLeft: 10, marginRight: 10, marginBottom: 5 }} justifyContent="right">
                            <Button onClick={close}>Back</Button>
                            <Button onClick={saveSettings}>Save</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            {snackShow && <Snack message='User settings saved' show={snackShow} onAutoClose={resetSnackData} severity='success' />}
        </Container>
    )
}