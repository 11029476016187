import Box from "@mui/material/Box";
import React from "react";
import Ownpremises from "./../components/Ownpremises";
import Container from "@mui/material/Container"
import { DataGrid } from "@mui/x-data-grid";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";

export default function HomeView() {
  return (
    <Box sx={{ width: "80%" }}>
      <Ownpremises />
    </Box>

  );
}
