import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Alert

} from '@mui/material';

import { useEffect, useState } from 'react';
import { useTheme } from "@mui/material/styles";

export default function ConnectionLogDialog({ open, connectionLog, onClose }) {

    const [conLog, setConLog] = useState([]);
    const [liveItem, setLiveItem] = useState({});

    const theme = useTheme();

    useEffect(() => {
        if (!connectionLog) return;
        let temp = [];
        for (let i = 0; i < connectionLog.length; i++) {
            let current = connectionLog[i];
            let next = connectionLog[i - 1];
            let duration = 0;
            let item = { status: current.status, timestamp: current.timestamp };
            if (i === 0) {
                duration = (new Date().getTime() - new Date(current.timestamp).getTime()) / 1000;
                item.live = true;
                setLiveItem(item);
            } else {
                duration = (new Date(next.timestamp).getTime() - new Date(current.timestamp).getTime()) / 1000
            }
            item.duration = secondsToString(duration);
            temp.push(item);
        }
        setConLog(temp);
        console.log("updated connection log:", temp);
    }, [connectionLog]);

    useEffect(() => {
        const interval = setInterval(() => {
            //console.log("jee")
            if (conLog.length) {
                let newArr = conLog.map((item) => {
                    if (item.live) {
                        item.duration = secondsToString((new Date().getTime() - new Date(item.timestamp).getTime()) / 1000);
                    }
                    return item;
                })
                setConLog(newArr);
            }
        }, 1000);
    }, []);

    const secondsToString = (seconds) => {
        var days = Math.floor(seconds / 86400);
        var hours = Math.floor((seconds % 86400) / 3600);
        var mins = Math.floor(((seconds % 86400) % 3600) / 60);
        var secs = Math.floor(((seconds % 86400) % 3600) % 60);
        var rets = '';
        if (days > 0) rets += days + "d ";
        if (hours > 0) rets += hours + "h ";
        if (mins > 0) rets += mins + "m ";
        return rets + secs + "s";
    }
    
    return (
        <Dialog
            open={open}
            keepMounted
            fullWidth={true}
            maxWidth='lg'
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>Connection Log</DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <TableContainer component={Paper} sx={{ marginTop: 1, maxHeight: 600 }}>
                        <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell >Timestamp</TableCell>
                                    <TableCell >Duration</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {conLog.map((log) => (
                                    <TableRow key={log.timestamp} hover={true} >
                                        <TableCell ><Alert icon={false} severity={log.status === 'connected' ? 'success' : 'warning'}>{log.status}</Alert></TableCell>
                                        <TableCell >{new Date(log.timestamp).toLocaleString()}</TableCell>
                                        <TableCell >{log.duration}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}