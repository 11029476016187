import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import { version } from "../../package.json";
import useAuth from "../auth/useAuth";
import Collapse from "@mui/material/Collapse"

import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/Inbox";
import MailIcon from "@mui/icons-material/Mail";
import GasMeter from '@mui/icons-material/GasMeter';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HistoryToggleOff from '@mui/icons-material/HistoryToggleOff';
import SavedSearch from '@mui/icons-material/SavedSearch';
import DeleteIcon from '@mui/icons-material/Delete';


import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Grid,
} from "@mui/material";

import { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

export default function Appbar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { user, authenticated, logout } = useAuth();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [history, setHistory] = useState([]);
    const [historyOpen, setHistoryOpen] = useState(true);
    const [ownDevicesOpen, setOwnDevicesOpen] = useState(true);
    const [ownDevices, setOwnDevices] = useState([]);


    let own = [];
    //let history = [];

    useEffect(() => {
        let settings = localStorage.getItem('usersettings');
        if (settings) {
            settings = JSON.parse(settings);
            //{"autoopensearchresults":true,"autoopenowndevices":true}
            setHistoryOpen(settings.autoopenhistory ? settings.autoopenhistory : false);
            setOwnDevicesOpen(settings.autoopenowndevices ? settings.autoopenowndevices : false);
        }
    }, []);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout();
        setAnchorEl(null);
    };

    const openProfile = () => {
        setAnchorEl(null);
        navigate('/profile')
    }

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        getHistory();
        getOwnDevices();
        setDrawerOpen(open);
    };

    const getHistory = () => {

        let lhistory = localStorage.getItem('history');
        if (lhistory) {
            lhistory = JSON.parse(lhistory);
            setHistory(lhistory);
        }
        //console.log("historia", lhistory);
    };

    const getOwnDevices = () => {
        let lownDevices = localStorage.getItem('ownDevices');
        if (lownDevices) {
            lownDevices = JSON.parse(lownDevices);
            setOwnDevices(lownDevices);
        }
    }

    const getOwn = () => {
        own = user.ownPremises;
        console.log(own);
    };

    const toggleHistory = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setHistoryOpen(!historyOpen);
    }

    const toggleOwnDevices = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOwnDevicesOpen(!ownDevicesOpen);
    }
    const removeOwnItem = (item, e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("removeOwnItem", item, e);
        let lownDevices = localStorage.getItem('ownDevices');
        if (lownDevices) {
            lownDevices = JSON.parse(lownDevices);
            lownDevices.splice(lownDevices.indexOf(item), 1);
            localStorage.setItem('ownDevices', JSON.stringify(lownDevices));
            setOwnDevices(lownDevices);
        }
    }

    const menuList = () => {
        return (
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <List>
                   
                    <ListItem key="History" name="historyToggle" id="historyToggle" disablePadding onClick={toggleHistory}>
                        <ListItemButton id="historyButton">
                            <ListItemIcon>
                                <HistoryToggleOff />
                            </ListItemIcon>
                            <ListItemText primary="History" />
                            {historyOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={historyOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {history.map((item) => (
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/device/' + item.id)}>
                                    <ListItemIcon>
                                        <GasMeter />
                                    </ListItemIcon>
                                    <ListItemText primary={item.id} secondary={item.name} />

                                </ListItemButton>
                            ))}
                        </List>
                    </Collapse>
                    <ListItem key="OwnDevices" name="historyToggle" id="historyToggle" disablePadding onClick={toggleOwnDevices}>
                        <ListItemButton id="OwnDevices">
                            <ListItemIcon>
                                <SavedSearch />
                            </ListItemIcon>
                            <ListItemText primary="Own Devices" />
                            {ownDevicesOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={ownDevicesOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {ownDevices.map((item) => (
                                <ListItem secondaryAction={
                                    <IconButton edge="end" onClick={(e) => { removeOwnItem(item, e) }} aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                } sx={{ pl: 4 }} onClick={() => navigate('/device/' + item.id)} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <GasMeter />
                                        </ListItemIcon>
                                        <ListItemText primary={item.id } secondary={item.name} />
                                    </ListItemButton>

                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </List>
                <Divider />
            </Box>
        );
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar sx={{ textAlign: "center" }}>
                    <Grid
                        container
                        direction="row"
                        sx={{
                            gridAutoColumns: "1fr",
                            gap: 1,
                            display: "grid",
                        }}
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            gridRow={1}
                            justifyContent="flex-start"
                        >
                            <Typography variant="h6">
                                {authenticated && (
                                    <IconButton onClick={toggleDrawer(true)}>
                                        <MenuIcon sx={{ color: "white" }} />
                                    </IconButton>
                                )}
                                <Typography sx={{ cursor: 'pointer' }} display='inline' fontSize={20} onClick={() => { navigate('/home') }}>Lifecycle dashboard </Typography>
                                <Typography variant="body2" display="inline" conponent="span">
                                    {" "}
                                    v.{version}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="flex-end"
                            gridRow={1}
                        >
                            <Typography>
                                <Typography>
                                    <Button
                                        sx={{ color: "white" }}
                                        id="basic-button"
                                        aria-controls={open ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                        onClick={handleClick}
                                    >
                                        {user?.email}
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            "aria-labelledby": "basic-button",
                                        }}
                                    >
                                        <MenuItem onClick={openProfile}>Settings</MenuItem>
                                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                    </Menu>
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
                {authenticated && (
                    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                        {menuList()}
                    </Drawer>
                )}
            </AppBar>
        </Box>
    );
}
