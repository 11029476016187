import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Collapse,
    Box,
    Typography,
    TextField
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { useState } from 'react';
import React from 'react';

import { useTheme } from "@mui/material/styles";

export default function AlarmsDialog({ open, alarms, deviceId, onClose }) {

    //console.log("Alarms:", alarms);

    return (
        <Dialog
            open={open}
            keepMounted
            fullWidth={true}
            maxWidth='lg'
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{deviceId} Alarms</DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <TableContainer component={Paper} sx={{ marginTop: 1 }}>
                        <Table sx={{ paddingTop: 5 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Alarm</TableCell>
                                    <TableCell align="center">Start</TableCell>
                                    <TableCell align="center">End</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {alarms.map((alarm) => (
                                    <Row alarm={alarm} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

function Row(props) {
    const { alarm } = props;
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    let color = 'white';

    if (!alarm.end) {
        color = theme.palette.warning.main;
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: color }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {alarm.name}
                </TableCell>
                <TableCell align="center">{new Date(alarm.start).toLocaleString()}</TableCell>
                <TableCell align="center">{alarm.end ? new Date(alarm.end).toLocaleString() : ''}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Alarm data:
                            </Typography>
                            <TextField multiline fullWidth value={JSON.stringify(alarm.eventHistory[0], undefined, 4)} />
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
