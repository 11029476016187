import { useEffect, useState } from 'react';
import useAuth from '../auth/useAuth';
import {
    Paper,
    Container,
    Typography,
    Button,
} from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import config from '../auth/config';
import Snack from '../components/Snack';

export default function LoginView() {
    const { authenticated, login } = useAuth();
    const { token, user } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [ showError, setShowError] = useState(false);

    if (token && user) {
        useEffect(() => {
            try {
                const userData = JSON.parse(Buffer.from(user, 'base64').toString('utf8'));
                //console.log("UserData:",userData);
                login(token, userData);
            } catch (err) {
                console.error("Login failed:", err);
            }
        })
    }

    console.log("location:",location);
    useEffect(() => {
        if (authenticated) {
            //console.log("Authenticated");
            navigate('/home');
        }
        if(location.pathname==="/login/unauthorized"){
            setShowError(true);
        }
    });

    const handleLogin = () => {
        window.location.href = config.loginUrl;
    }

    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ minHeight:350, textAlign: 'center' }}>
                <Typography sx={{ paddingTop: 1 }} variant="h4" >Welcome to Lifecycle dashboard</Typography>
                <Typography sx={{ paddingTop: 15 }}>
                    <Button size='large' onClick={handleLogin}>Login</Button>
                </Typography>
            </Paper>
            <Snack message="Unauthorized" severity="error" show={setShowError} />
        </Container>
    )
}