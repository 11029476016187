import { useNavigate } from 'react-router-dom';
import {
    Paper,
    Container,
    Typography,
    Button,
} from '@mui/material';


export default function ErrorPage() {

    const navigate = useNavigate();

    const returnHome = function(){
        navigate('/home')
    }

    return(
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ marginTop: 0,paddingBottom:2, textAlign: 'center' }}>
                <Typography>
                    <img src='404.gif' style={{maxwidth:800,width:600}} />
                </Typography>
                <Typography variant='h5'>
                    That's an error!
                </Typography>
                <Button onClick={returnHome}>Return home</Button>
            </Paper>
        </Container>
    )
}