import axios from 'axios';
import { createContext, useState, useContext, useEffect } from 'react';
import config from './config';

const authContext = createContext({});

function useAuthentication() {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [iotToken, setIotToken] = useState(null);

    useEffect( () => {
        let data = sessionStorage.getItem('user');
        if (!data) {
            data = localStorage.getItem('user');
        }
        let tokendata = sessionStorage.getItem('token');
        if (!tokendata) {
            tokendata = localStorage.getItem('token');
        }
        if (data && tokendata) {
            console.log("user loaded from storage");
            setUser(JSON.parse(data));
            setToken(tokendata);
            setAuthenticated(true);
            getIotToken(tokendata);
        } else {
            console.log("no user data found.");
        }


    }, []);

    const getIotToken = async (token) => {
        try {
            let storedToken = sessionStorage.getItem('iottoken');
            if (storedToken) {
                setIotToken(storedToken);
            } else {
                let resp = await axios.get(config.flowoneApiUrl + '/lifecycle/iottoken', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                });
                console.log("Response from API:", resp);
                let iottoken = resp.data;
                setIotToken(iottoken);
                sessionStorage.setItem('iottoken', iottoken);
            }
        } catch (err) {
            console.error("Error getting iot token:", err);
        }
    }

    return {
        user,
        authenticated,
        token,
        iotToken,
        login: async (token, user, rememberme) => {
            //console.log("useAuth login called with:", user);
            try {
                setUser(user);
                setToken(token);
                setAuthenticated(true);
                getIotToken(token);
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', token);
                if (rememberme) {
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('token', token);
                }
            } catch (err) {
                console.error("Error setting user data:", err);
            }
        },
        logout: async () => {
            console.log("Logout called:");
            setUser(null);
            setAuthenticated(false);
            sessionStorage.removeItem('user');
            localStorage.removeItem('user');
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
            sessionStorage.removeItem('iottoken');
        },
    };
};

export function AuthProvider({ children }) {
    const auth = useAuthentication();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export default function useAuth() {
    return useContext(authContext);
}
