import { HashRouter, Routes, Route } from "react-router-dom";
import LoginView from "./views/LoginView";
import AppBar from "./components/AppBar";
import Box from "@mui/material/Box";
import { AuthProvider } from "./auth/useAuth";
import React from 'react';
import RequireAuth from './auth/RequireAuth';
import HomeView from './views/HomeView';
import ErrorPage from './views/ErrorPage';
import DeviceView from './views/DeviceView';
import ProfileView from "./views/ProfileView";

export function App() {
    return (
        <>
                <AuthProvider>
                    <HashRouter>
                        <AppBar />
                        <Box sx={{
                            display: 'flex',
                            justifyContent: "center",
                        }}>
                            <Routes>
                                <Route path="/login" element={<LoginView />} />
                                <Route path="/login/callback/:token/:exp/:user" element={<LoginView />} />
                                <Route path="/login/unauthorized" element={<LoginView />} />
                                <Route path="/" element={<RequireAuth><HomeView /></RequireAuth>} />
                                <Route path="/home" element={<RequireAuth><HomeView /></RequireAuth>} />
                                <Route path="/profile" element={<RequireAuth><ProfileView /></RequireAuth>} />
                                <Route path="/device">
                                    <Route path=":deviceId" element={<RequireAuth><DeviceView /></RequireAuth>} />"
                                </Route>
                                <Route path="*" element={<ErrorPage />} />
                            </Routes>
                        </Box>
                    </HashRouter>
                </AuthProvider>
        </>
    )
}
