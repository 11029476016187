module.exports = function (emitter, event) {
  let listener = null;

  const promise = new Promise(function (resolve, reject) {
    listener = function (...ars) {
      let args = null;

      if (ars.length === 1) {
        args = ars[0];
      } else {
        args = [];

        for (let i = 0; i < ars.length; i++)
          args.push(ars[i]);
        }

        resolve(args);
      };

    emitter.once(event, listener);
  });

  promise.cancel = function () {
    emitter.removeListener(event, listener);
  };

  return promise;
};